<template>
    <div style="min-height:100%; background: #fff;padding: 0px 20px 0;margin: 0;">
        <a-layout-content style="height: 100%; width: 100%;background:#fff">
            <!--<div style="display:none">
                <a-row :gutter="[16, 16]">
                    <a-col :span="4" v-for="info in tableData" :key="info.ID">
                        <a-card hoverable :bordered="true" style="height: 320px">
                            <img slot="cover"
                                 :src="Getsrc(info)"
                                 class="thumb-img1"
                                 @click="Store_PackageDetail(info.ID)" />
                            <a-card-meta :title="info.Name">
                                <template slot="description">

                                </template>
                            </a-card-meta>
                        </a-card>
                    </a-col>
                </a-row>
                <a-row style="padding:20px;">
                    <a-col :span="24">
                        <a-pagination v-model="current"
                                      :total="total"
                                      :defaultPageSize="defaultPageSize"
                                      :showTotal="showTotal"
                                      @change="pagerChange" />
                    </a-col>
                </a-row>
            </div>-->
            <a-form layout="horizontal" style="padding: 10px 0px 0; height: auto">
                <a-tabs default-active-key="0">
                    <a-tab-pane key="0" tab="模板列表" />
                </a-tabs>
            </a-form>
            <!--<a-form-item label=""
                         style="margin-bottom: 10px; display:none"
                         :label-col="formItemLayout.labelCol"
                         :wrapper-col="formItemLayout.wrapperCol">
                <a-form layout="inline">
                    <a-form-item label="类型">
                        <a-radio-group default-value="TypeID"
                                       v-model="TypeID"
                                       @change="QueryData"
                                       button-style="solid">
                            <a-radio-button :value="item.ID"
                                            v-for="item in TemplateTypeList"
                                            :key="item.ID">
                                {{ item.Name }}
                            </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                </a-form>
            </a-form-item>-->
            <a-form-item label=""
                         style="margin-bottom: 10px"
                         :label-col="formItemLayout.labelCol"
                         :wrapper-col="formItemLayout.wrapperCol">
                <a-form layout="inline">
                    <a-form-item label="分类">
                        <a-radio-group default-value="ClassID"
                                       v-model="URLMaterialType"
                                       @change="QueryData"
                                       button-style="solid">
                            <a-radio-button :value="item.ID"
                                            v-for="item in TemplateClassList"
                                            :key="item.ID">
                                {{ item.Name }}
                            </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                </a-form>
            </a-form-item>

            <div class="muban">
                <div class="muban-item" v-for="info in StoretableData" :key="info.MaterialID">
                    <img class="thumb-img" slot="cover" :src="StoreGetsrc(info)" />
                    <div class="sc-txt">
                       {{info.Name}}
                       <br/>
                        <a-checkbox @change="onChange($event,info.ID)" :checked="defaultMid==info.ID?true:false"></a-checkbox>
                         <br/>
                    </div>
                </div>
              
            </div>


            <a-row style="margin: 10px 0;padding:20px 0;">
                <a-col :span="24">
                    <a-pagination v-model="Storecurrent"
                                  :total="Storetotal"
                                  :defaultPageSize="StoredefaultPageSize"
                                  :showTotal="StoreshowTotal"
                                  @change="StorepagerChange" />
                </a-col>
            </a-row>
        </a-layout-content>
         <a-modal
        v-model="visible"
        :maskClosable="true"
        :closable="false"
        width="7%"
        height="40px"
        footer
      >
       <!-- <el-select
                  v-model="CreateType"
                  placeholder="选择素材类型"
                  @change="selectChangeContent"
                  style="width:210px;margin-left:3px;"
                >
                  <el-option label="海报" value="0"></el-option>
                  <el-option label="文章" value="1"></el-option>
                  <el-option label="H5" value="2"></el-option>
     </el-select> -->
     
      <a-button  style="margin-left:0.5%;margin-top:10px;" @click="CreaateMaterial">创建素材</a-button>
      </a-modal>
        <div v-if="DetailVisible">
            <Detail :closeDetail="closeDetail" :MaterialType="MaterialType" :ID="ID" :PushID="PushID" :IDType="1" :disabled=false></Detail>
        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import Detail from '@/Views/MaterialModule/M2Material/Detail';
    export default {
        name: "PackageList",
        data() {
            return {
                TemplateClassList: [{
                    ID: 4,
                    Name: "名片",
                },
                {
                   ID: 3,
                   Name: "店铺",
                }
                ],
                TemplateTypeList: [],
                tableData: [],
                StoretableData: [],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                Topic: { Name: "全部", ID: 0 },
                Business: { Name: "全行业", ID: 0 },
                current: 1,
                total: 0,
                defaultPageSize: 6,
                showTotal: (total) => `共 ${total} 条`,
                Storecurrent: 1,
                Storetotal: 0,
                StoredefaultPageSize: 12,
                StoreshowTotal: (Storetotal) => `共 ${Storetotal} 条`,
                MaterialType: -1,
                PushID: "",
                ID: "0",
                DetailVisible: false,
                TypeID: -1,
                ClassID: -1,
                URLMaterialType: 4,
                CreateMaterialType:-1,
                SubjectID: "",
                defaultMid: 0,//默认模板的ID
                TemplateID:0,
                visible:false,
                CreateType:"",
            };
        },
        props: {
            //组件属性

        },
        methods: {
            //获取店铺默认配置
            GetSystemConfigurationTemp() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetSystemConfigurationTemp",
                    data: {},
                    OnSuccess: function (data) {
                        console.log("你好");
                        console.log(data.data["CardTmplID"]);
                        if (data.data != null && self.URLMaterialType == 4)//名片
                            self.defaultMid = data.data["CardTmplID"] == null ? 0 : data.data["CardTmplID"];
                        if (data.data != null && self.URLMaterialType == 3)//店铺
                            self.defaultMid = data.data["StoreMID"] == null ? 0 : data.data["StoreMID"];
                    },
                };
                http.Post(op);
            },
            //修改默认配置
            editSystemConfigurationTemp(Mid,MaterialID) {
                this.defaultMid = Mid;
                var op = {
                    url: "/MarketingModule/Material/editSystemConfigurationTemp",
                    data: { "Mid": Mid, "Mtype": this.URLMaterialType,"MaterialID": MaterialID },
                    OnSuccess: function (data) {
                        //console.log("你好");
                        console.log(data.data);
                    },
                };
                http.Post(op);
            },
            onChange(e, Mid) {
                var self=this;
                console.log("MaterialID：",Mid);
                console.log(`checked = ${e.target.checked}`);
                if (self.URLMaterialType == 3){
                    self.GetTemplateConfig(Mid);
                    self.GetTemplateMaterialID(Mid,e);
                }
                else{
                    if (e.target.checked)
                        self.editSystemConfigurationTemp(Mid);
                    else
                        self.editSystemConfigurationTemp(0);
                }
               
            },
            //获取店铺模板对应的素材ID
            GetTemplateMaterialID(TmID,e) {
              console.log(e);
                let self = this;
                let op={
                    url:"/MarketingModule/Material/Ac_GetTemplateMaterialID",
                    data:{"TempID":TmID},
                        OnSuccess:function(data){
                          console.log(data.data.Data);
                          if(data.data.Result){
                            if(e.target.checked)
                            {
                              if(data.data.Data!=null){
                                  self.$router.push({
                                    name: "Store_MyListShow", query: {
                                      mid:data.data.Data,
                                      MaterialType: 3,
                                      TmplID: TmID,
                                    }
                                  });
                                  self.editSystemConfigurationTemp(TmID,data.data.Data);
                              }else{
                                self.visible=true;
                                localStorage.setItem("Mid",TmID);
                              }
                            }
                            else{
                              //self.visible;
                              self.editSystemConfigurationTemp(0);
                            }
                          }
                        }
                      };
                http.Post(op);
            },

            GetTemplateConfig:function(Mid){
              var self=this;
              var op={
                  url:"/MarketingModule/Material/GetTemplateConfig",
                  data:{"TemplateID":Mid},
                  OnSuccess:function(data){
                       console.log(data.data);
                       self.TemplateID=data.data;
                  }
               }
            http.Post(op);
            },
            GetTemplateType: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetTemplateType",
                    data: {},
                    OnSuccess: function (data) {
                        self.TemplateTypeList.push({ Name: "全部", ID: -1 });
                        data.data.forEach((e) => {
                            self.TemplateTypeList.push({ Name: e.Name, ID: e.ID });
                        });
                        self.GetTemplateClass();
                    },
                };
                http.Post(op);
            },
            GetTemplateClass: function () {
                // var self = this;
                // var op = {
                //     url: "/MarketingModule/Material/GetTemplateClass",
                //     data: {},
                //     OnSuccess: function (data) {
                //         self.TemplateClassList.push({ Name: "全部", ID: -1 });
                //         data.data.forEach((e) => {
                //             self.TemplateClassList.push({ Name: e.Name, ID: e.ID });
                //         });
                //         self.getTableData();
                //     },
                // };
                // http.Post(op);
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialPackageList",
                    data: {
                        Topic: self.Topic.ID,
                        pageIndex: self.current,
                        PageCount: self.defaultPageSize,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.total = data.data.Total;
                        self.defaultPageSize = data.data.RowCount;
                        self.current = data.data.PageIndex;
                        self.StoregetTableData();
                    },
                };
                http.Post(op);
            },
            StoregetTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetSystemTemplateList",
                    data: {
                        ClassID: self.ClassID,
                        pageIndex: self.Storecurrent,
                        MaterialType: self.URLMaterialType,
                        PageCount: self.StoredefaultPageSize,
                    },
                    OnSuccess: function (data) {
                        self.StoretableData = data.data.Data;
                        self.Storetotal = data.data.Total;
                        self.StoredefaultPageSize = data.data.RowCount;
                        self.Storecurrent = data.data.PageIndex;
                    },
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.current = item;
                self.getTableData();
            },
            changeTopic: function (e) {
                var self = this;
                self.Topic = e;
                self.current = 1;
                self.getTableData();
            },
            changeBusiness: function (e) {
                var self = this;
                self.Business = e;
                self.current = 1;
                self.getTableData();
            },
            GetPackageRule: function (info) {
                var rt = "";
                if (info.PackageRule == null) {
                    rt = "未配置";
                } else {
                    switch (info.PackageRule.PublishFrequency) {
                        case 0:
                            rt = "";
                            break;
                        case 1:
                            rt = "每日AM" + info.PackageRule.PublishTime + "推送";
                            break;
                        case 2:
                            var List = [];
                            info.PackageRule.PackageRuleItem.forEach((e) => {
                                List.push(e.PeriodIndex);
                            });
                            var PackageRuleItem = List.join("、");
                            PackageRuleItem = PackageRuleItem.replace("0", "日")
                                .replace("1", "一")
                                .replace("2", "二")
                                .replace("3", "三")
                                .replace("4", "四")
                                .replace("5", "五")
                                .replace("6", "六");
                            rt =
                                "每周" +
                                PackageRuleItem +
                                "AM" +
                                info.PackageRule.PublishTime +
                                "推送";
                            break;
                    }
                }
                return rt;
            },
            EditSubscription(e) {
                var self = this;
                var con = e.Subscriptionbool ? "取消订阅" : "订阅";
                this.$confirm({
                    title: "提示",
                    content: "是否" + con + e.Name,
                    okText: "确定",
                    cancelText: "取消",
                    onOk() {
                        var Status = e.Subscriptionbool ? 0 : 1;
                        var op = {
                            url: "/MarketingModule/Material/EditSubscription",
                            data: {
                                PackageID: e.ID,
                                Status: Status
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.getTableData();
                                self.$message.success(con + "成功");
                            },
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            Getsrc: function (info) {
                return info.ImgTopic.replace("upload", "upload2");
            },
            Store_PackageDetail(e) {
                this.$router.push({
                    name: "Store_PackageDetail",
                    query: { id: e },
                });
            },
            StoreGetsrc: function (info) {
                return info.PreViewImg.replace("upload", "upload2");
            },
            StorepagerChange: function (item) {
                var self = this;
                self.Storecurrent = item;
                self.StoregetTableData();
            },
            QueryData() {
                console.log("Class:", this.ClassID);
                if (this.URLMaterialType == 4)
                    this.ClassID = -1;
                if (this.URLMaterialType == 3)
                    this.ClassID = 1;
                var self = this;
                self.Storecurrent = 1;
                self.StoregetTableData();
                //获取店铺默认配置
                self.GetSystemConfigurationTemp();
            },
            ShowDetail(e) {
                var self = this;
                self.MaterialType = e.MaterialType;
                self.PushID = e.ID + "";
                self.DetailVisible = true;
            },
            NewShowDetail: function (e) {
                var self = this;
                self.DetailVisible = true;
                if (e.MaterialType == 5) {
                    self.$router.push({
                        name: "Store_NewPoster", query: {
                            MaterialType: e.MaterialType,
                            TmplID: e.ID,
                        }
                    })
                }
                if (e.MaterialType == 3) {
                    self.$router.push({
                        name: "Store_NewH5", query: {
                            MaterialType: e.MaterialType,
                            TmplID: e.ID,
                        }
                    })
                }
            },



            closeDetail(e) {
                console.log(e);
                var self = this;
                self.DetailVisible = false;
            },
            getUrlKey: function (name) {
                return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [""])[1].replace(/\+/g, '%20')) || null
            },
            selectChangeContent:function(item){
                var self=this;
                self.CreateMaterialType = item;
            },
            CreaateMaterial:function(){
                var self=this;
                // self.$router.push({
                //    name: "M2Material_AddExtension", query: {
                //         MaterialType: 1,
                //     }
                // })
                self.$router.push({
                    name: "Store_NewH5",
                    query: {
                        MaterialType:3,
                        TmplID:localStorage.Mid
                    }
                });
                // var url_name="";
                // var MaterialType=0;
                // switch (self.CreateType) {
                //     case "1":
                //         url_name ="M2Material_AddExtension";
                //         MaterialType = 1;
                //         break;
                //    case "0":
                //         url_name ="Store_PackageList";
                //         MaterialType = 5;
                //         break;
                //    case "2":
                //         url_name ="Store_PackageList";
                //         MaterialType = 3;
                //         break;      
                //     default:
                //         break;
                // }
            },
            CreateTemplateCongfig:function(){
                var op={
                    url: "/MarketingModule/Material/UpdateTemplateConfig",
                    data: {
                        MaterialID: localStorage.MaterialID,
                        TemplateID: localStorage.Mid,
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                    },
                }
                http.Post(op);
            },
        },
        computed: {
            //计算属性
        },
        watch: {
        },
        components: {
            Detail
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        mounted: function () {
            var self = this;
            var mid=localStorage.Mid;
            if(mid != undefined && localStorage.MaterialID!=undefined){
                //var MaterialID=localStorage.MaterialID;
                self.defaultMid = mid;
                self.URLMaterialType = 3;
                self.ClassID = 1;
                self.editSystemConfigurationTemp(mid,localStorage.MaterialID);
                self.CreateTemplateCongfig();
            }
            //加载列表;
            self.StoregetTableData();
            //系统配置
            self.GetSystemConfigurationTemp();
            window.addEventListener( 'beforeunload',localStorage.clear() );
        },
        beforeDestroy: function () {//生命周期，销毁前
           if(localStorage.MaterialID!=undefined)
           {
               localStorage.clear();
           }
        },
    };
</script>
<style scoped>
    .muban {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        gap: 25px;
        padding: 25px;
        width: 100%;
        margin: 0 auto;
    }

    .muban-item {
        border: 1px solid #f4f4f4;
        border-radius: 8px;
        overflow: hidden;
        max-height: 505px;
    }

        .muban-item:hover {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
            transition: all ease 0.3s;
        }

            .muban-item:hover .sc-txt {
                color: #2281f8
            }

    .sc-txt {
        display: block;
        font-size: 16px;
        line-height: 20px;
        color: #3B3B3B;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        padding: 8px 5px;
    }

        .sc-txt:hover {
            color: #2281f8
        }

    ::v-deep .ant-card-body {
        padding: 5px
    }

    .mintro {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        white-space: normal;
    }

    .mintro2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        height: 36px;
        white-space: normal;
    }

    .thumb-img1 {
        height: 150px;
        object-fit: cover;
        outline: none;
        background: url("../../../../src/assets/nourl.png");
        background-size: cover;
    }

    .thumb-img {
        cursor: pointer;
        min-height: 400px;
        border-radius: 5px;
        width: 100%;
        height: 450px;
        outline: none;
        border: none;
        border-radius: 10px 10px 0 0;
        background: #EBEBEB url("../../../../src/assets/nourl.png") no-repeat center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

        .thumb-img:hover {
            opacity: 0.85;
        }

    .option {
        margin: 10px 0;
    }
</style>